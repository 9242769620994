.dashboard-container {
  padding: 0 20px 30px;
  height: calc(100vh - 160px); 
  overflow: hidden;
  overflow-y:auto;
  padding: 20px 0;
  scrollbar-width: none;
}

.welcome-text {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
}

.welcome-text h2{font-weight: 400; font-size: 24px; line-height: 29px; color: #667085;}


.organization-list-container {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  max-height: calc(100vh - 230px);
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: none;   

}
.organization-list-container h2{margin-bottom: 16px; font-weight: 600;}
.organization-item.add-organization{ background: transparent; color: #667085; font-weight: 400;}
 
.organization-list {
  margin-top: 15px;
  display: flex; 
  flex-wrap: wrap;
  flex-direction: unset;
  justify-content: space-between;
  gap:16px;  
  overflow: hidden;
  overflow-y: auto;
}

.organization-item {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  max-width: 49%;
  width: 100%; 
  border-top:1px solid #E0E0E0 !important;
  background: #FAFAFA;
  color: #040F0F;
  font-size:16px;
  line-height: 24px;

}

.organization-item:hover {
  background-color: #f9f9f9;
}

.add-organization {
  color: #007bff;
  font-weight: bold;
}

/* .edit-btn {
  margin-top: 0px;
  font-size: 16px;
  background: #FAFAFA;
  color: #667085;
  border-color:#E0E0E0;
  width: 272px;
  border-radius: 6px;
} */



.parent-class{display: flex; justify-content: space-between; align-items: center;}
.welcome-text h4 span{font-weight: 600; color: #040F0F; }
.desk-logo{display: block;}


.control-btns{display: flex; gap:16px; }
.control-btns button{padding: 0px; font-size: 16px; border: none; box-shadow: none; text-decoration: none; color: #2F2D91 !important;;}
.control-btns button:hover{color: #3D95A8 !important;}


.edit-organization button{padding: 7px 60px; background:#FAFAFA; border:1px solid #E0E0E0;  color: #667085; max-width: 296px; width: 100%; text-align: center;}
.edit-organization button:hover{background: #2E3394; color: #fff;}


.edit-organization .edit-btn{background: #3D95A8; color: #fff; font-size: 16px;}
.edit-organization .edit-btn:hover{background: #2E3394; }


/* @media only screen and (max-width:1650px){
  .dashboard-container { min-height: calc(100vh - 180px);}
} */

@media (max-width: 768px) {
  .edit-btn {
    /* margin-top: 15px; */
    width: 100%;
  }
}

@media only screen and (max-width:991px){
  .organization-list-container{margin: 0px !important; margin-top: 20px !important; }
  .organization-item{max-width: 48%;}
  .organization-list-container-outer{padding:20px 10px !important;}
  .welcome-text{text-align: left;}
  .edit-organization button{padding: 7px 20px;}
  .organization-list-container{
    margin-bottom: 30px !important;
  }
  .welcome-text{
    margin-bottom: 10px;
    width: 70%;
  }
}


@media only screen and (max-width:767px){
  .parent-class{flex-wrap: wrap;}
  .welcome-text h4{font-size: 20px; }
  .edit-organization{width: 100%;}  
  .organization-item{max-width: 100%;}
  .dashboard-container{min-height: inherit; height: auto;}
  .organization-list-container{height: auto;}
  .dashboard-container {height: auto;}

  .welcome-text h2{font-size: 20px; line-height: 24px;}
  .edit-organization button{max-width: inherit;}
  .welcome-text{
    width: 100%;
  }
  .welcome-text{
    margin-bottom: 0;
  }
  
} 

@media only screen and (max-width:575px){
  .edit-organization{width: 100%;}  
  .organization-item{max-width: 100%;}
  .organization-list-container{border:none; box-shadow: none; padding:0 !important;}
  .welcome-text h4{text-align: left;}
  .organization-list-container-outer{padding: 0px !important;}
  .welcome-text h4{margin-bottom: 0px;}

  .edit-organization button{width: 100%; margin: 0px;}
  .edit-organization{margin-top: 20px;}
  .organization-list-container{margin-bottom: 20px !important;}
  .welcome-tex{margin-bottom: 0px;}

  .welcome-text h2 br{display: block;}

  .dashboard-container{margin-bottom: 60px;}
  .organization-name{
    max-width: 30ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  span.team-arrow {
    position: relative;
    top: -4px;
}
}