.team-container{border-radius: 16px; border:1px solid #E0E0E0; padding: 30px; margin-top: 20px;}   
.team-container span{color: #667085; display: inline-block; margin-left: 0px; font-size: 24px; font-weight:600; cursor: pointer;}
.add-team{padding: 25px;}
.edit-team-btn button{border:1px solid #E0E0E0; color: #667085;  font-size: 16px; line-height: 28px; padding: 5px 48px; border-radius: 6px; background: #FAFAFA !important;}
.edit-team-btn button:hover{background: #2E3394 !important; color: #fff;}
.team-button-list{display: flex; margin-top: 16px; margin-bottom: 30px; gap:16px;}
.team-button-list button{padding: 13px 82px; display: inline-block; border-radius: 6px; border:1px solid transparent; color: #fff; }
.daskbtn{background: #2E3394;}
.alt-btn{background: #3D95A8;}

.daskbtn:hover{background: #3D95A8;}
.alt-btn:hover{background:#2E3394;}
.submit-forms-mn{width: 100%; }
.submit-forms-mn h4{margin-bottom: 0px;}
.submit-form-outer{margin-top: 0px;}

.submit-form-outer{width: 100%; }
.submit-form-box{background: #FAFAFA; padding: 13px 16px; border-radius: 6px; border: 1px solid #E0E0E0; color: #040F0F; text-align: left; font-weight: 400;}
.submit-forms-mn{margin-bottom: 32px;}
.submit-forms-mn:last-child{margin-bottom: 0;}

.edit-team-outer{display: flex; justify-content: space-between; width: 100%; align-items: flex-start; flex-wrap: wrap;}
.edit-team-left{display: flex; align-items: flex-start;  gap:8px;}
.edit-team-left input{background: #fff; font-weight: 600; border: 1px solid #D9D9D9; border-radius: 4px;  font-size: 24px; height: 66px;  line-height: 42px; padding: 12px 16px; margin-right: 16px;}
.team-time-select input{height: auto !important; background: #fff; border: 1px solid #D9D9D9; min-width: 400px; font-size: 24px; line-height: 42px; padding: 12px 16px;}


.edit-team-rgt{display: flex; gap:16px; padding-top: 6px;}
.edit-team-rgt button{border:1px solid #E0E0E0; color: #667085;  font-size: 16px; line-height: 28px; padding: 5px 44px; border-radius: 6px; background: #FAFAFA;}
.edit-team-rgt button:last-child{background: #3D95A8; color: #fff;}
.edit-team-rgt button:last-child:hover{background: #2E3394 !important;}
.edit-team-rgt button:hover{background: #2E3394 !important; color: #fff;}


.submit-form-box{display: flex; justify-content: space-between; align-items: center; }
.tema-box-edit-btns {display: flex; align-items: center; gap:16px;}
.tema-box-edit-btns button{border:none; background: transparent; font-size: 16px; line-height: 24px; color: #2E3394; padding: 0;}
.tema-box-edit-btns button:hover{color: #040F0F;}

.team-time-select .custom__control{height: 66px; border: 1px solid #D9D9D9; min-width: 400px; font-size: 24px; line-height: 40px; font-weight: 600; padding: 0 16px;}
.team-time-select .custom__value-container{padding: 0px 0; }
.custom__control--menu-is-open .custom__indicator {
	transform: rotate(180deg); /* Rotate arrow */
  }
  .team-title-btn{display: flex; flex: 0 0 auto; align-items: center;}
  .team-title-btn span{display: inline-block; margin-left: 8px; position: relative; top:-2px; font-size: 16px;}
  .team-title-btn span svg {fill: #3D95A8; }
/* .team-time-select .custom__single-value {
    color: #6A6A6A;
}     */
.team-title-btn span svg {fill: #3D95A8; font-size: 13px;}
.team-title-btn .pending-verification svg{fill: #E0E0E0;}

@media only screen and (max-width:1439px){
    .team-container{padding: 20px;}
    .submit-form-outer{grid-template-columns: repeat(3, 1fr) !important;}  

    .team-time-select .custom__control, .edit-team-left input{
      height: 50px;
      font-size: 20px; 
      line-height: 24px; 
      /* border: 1px solid #D9D9D9 !important;  */
    }
    .team-time-select .custom__control{min-width: 320px;}
}

@media only screen and (max-width:1199px){
    .edit-team-outer{flex-wrap: wrap;}
    .edit-team-left{width: 100%;}
    /* .edit-team-rgt{margin-top: 15px;} */
    .team-time-select .custom__control, .edit-team-left input{ 
      /* border: 1px solid #D9D9D9 !important;  */
    }
    .submit-form-outer{grid-template-columns: repeat(3, 1fr) !important;} 
}


@media only screen and (max-width:991px){
    .submit-form-outer{grid-template-columns: repeat(2, 1fr) !important;}  
    .team-button-list{margin-bottom: 20px; }

    .team-time-select .custom__control, .edit-team-left input{
      height: 44px; 
      font-size: 16px; 
      line-height: 24px; 
      /* border: 1px solid #D9D9D9 !important;  */
    }
}

@media only screen and (max-width:767px){
    .team-container{padding: 0px; border: none;}
    .team-content-title{flex-wrap: wrap;} 
    .team-button-list{flex-wrap: wrap;}
    .team-button-list button{width: 100%;}
    .submit-form-outer{grid-template-columns: repeat(1, 1fr) !important;}  
    .edit-team-btn{margin-top: 16px;}
    .team-content-title h2{width: 100%;}
    .team-content-title h2 span{text-transform: initial;}
    .edit-team-btn button{width: 100%;}
    .edit-team-btn{width: 100%;}
    .collapsed button{
        display: none;
      }

    .team-time-select .custom__control, .edit-team-left input{width: 100%; margin-right: 0;}
    .edit-team-left{gap:16px; display: flex; flex-wrap: wrap;}
    .edit-team-rgt{flex-wrap: wrap; width: 100%; flex-direction: column-reverse;}
    .edit-team-rgt button{width: 100%;}

    .add-team span{display:inline-block; font-size: 20px; line-height: 28px; border: 1px solid #E0E0E0; border-radius: 8px; padding:16px; width: 100%;  } 
    .team-content-title span{font-size: 20px; line-height: 24px;}
    .collapsed{margin-top: 0;}
    .team-time-select{width: 100%;}
    .edit-team-left{flex-wrap: nowrap;}
    .edit-team-left .team-time-select .custom__control, .edit-team-left input{height: 80px; margin-bottom: 10px;}
    .edit-team-left .team-time-select .custom__control{min-width: 100%;}
    .edit-team-left .custom__control input{display: none;}
    .custom__control--menu-is-open .custom__indicator {
        transform: rotate(180deg); /* Rotate arrow */
      }

    .team-name-input{
        max-width: 30ch;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
}


