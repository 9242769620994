@import 'bootstrap/dist/css/bootstrap.min.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');



*{
	box-sizing:border-box;
}
html{
	-webkit-text-size-adjust:100%;
	-ms-text-size-adjust:100%;
}
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
		margin:0;
		padding:0;
		border:0;
		outline:0;
	}
header, footer, article, section, hgroup, nav, figure {
		display:block;
		margin:0px;
		padding:0px;
}
img {
	border: 0 none;
	height: auto;
	max-width: 100%;
	vertical-align:middle;
	transition: all 0.5s ease-in-out 0s;
}
ul {
	list-style-type:none;
	padding:0px;
	margin:0px;
}
a, button{
		text-decoration:none;
		outline:none;
		transition: all 0.5s ease-in-out 0s;
		/* color:#222222; */
		color: #040F0F;
}
i.fa{
	transition: all 0.5s ease-in-out 0s;
}
p, td, input, label, form, h1, h2, h3, h4, h5, h6, a {
		padding:0px;
		margin:0px;
		border:none;
		text-decoration:none;
		font-weight:normal;
}
input[type="text"],input[type="button"],input[type="submit"],input[type="email"],input[type="tel"] ,textarea {
	-webkit-appearance: none;  
	-webkit-border-radius: 0;
	-webkit-appearance: none;
	transition: all 0.5s ease-in-out 0s;
	outline:none;
	resize: none;
	box-shadow: none; 

}
input[type="submit"], input[type="button"], input[type="reset"]{
	outline:none;
	cursor:pointer;
}

.form-control:focus{outline: none; box-shadow: none; border-color: #E0E0E0; }
.clear {
	clear: both; 
}
.vm{ 
	vertical-align: middle; 
}
.relative{ 
	position: relative; 
}
.left{
	float: left;
}
.right {
		float:right !important;
}
/*placeholder css*/
input::-webkit-input-placeholder {
		opacity:1 !important;
}

input::-moz-placeholder {
		opacity:1 !important;
}
input:-ms-input-placeholder {
		opacity:1 !important;
}
input:-moz-placeholder {
		opacity:1 !important;
}

textarea::-webkit-input-placeholder {
	opacity:1 !important;
}
textarea::-moz-placeholder {
	opacity:1 !important;
}
textarea:-ms-input-placeholder {
	opacity:1 !important;
}
textarea:-moz-placeholder {
	opacity:1 !important;
}

/*ends css*/
body{
	margin:0;
	padding:0;
	font-size:16px;
    line-height:normal; 
    color: #040F0F;
    font-weight:400; 
    font-family: "Inter", serif;
}

/*selection color*/
::-moz-selection {
    background: #000;
    color: #ffffff;
}
::-webkit-selection {
    background: #000;
    color: #ffffff;
}
::selection {
    background: #000;
    color: #ffffff;
}

/*======= Loader CSS =======*/
/* Loader.css */
.loader-container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.3); /* Optional: Add a background color */
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999; /* Ensure the loader is on top of other elements */
  }
  
  .loader-content {
	display: flex;
	justify-content: center;
	align-items: center;
  }
  .invalid__control{
    /* Styles for the control (input box) */
    border-color: #dc3545 !important;

  }
 
  .spinner-border{border:none; width: 80px; height: 80px; --bs-spinner-animation-speed: 2s;}

  .invalid__control:hover{
    border-color: #dc3545 !important;
  }

/*======= Common CSS =======*/
p{
	font-size:16px; 
	line-height:21px; 
}
h1{font-size: 40px; line-height: 48px; font-weight: 700;}	
h2{font-size:24px; line-height: 30px; font-weight: 600;}
h3{font-size: 20px; line-height: 28px; font-weight: 600;}
h4{font-size: 16px; line-height: 28px; font-weight: 600;}

a:hover, a:focus{
	text-decoration:none;
	color:inherit;
}

.form-field input{height: 44px; border-radius:8px; border: 1px solid #E0E0E0; color: #040F0F;}
.form-field .form-label{font-size: 14px; margin-bottom: 8px;  color: #040F0F; font-weight: 400;}
.form-check-input:focus{box-shadow: none; outline: none;} 

.alert-item .btn{padding: 0px 5px; line-height: 16px;}

/* .btn{border-color: #E0E0E0; color: #667085; border-radius: 6px; background:#FAFAFA; padding: 6px 30px;}
.btn:hover{color: var(--bs-btn-hover-color); background-color: var(--bs-btn-hover-bg); 	border-color: var(--bs-btn-hover-border-color);} */
.btn{transition: all 0.5s ease-in-out;}
.btn:hover{transition: all 0.5s ease-in-out;} 



.organization-overview-container::-webkit-scrollbar {
	width: 0px;	
	
  }
.list-group-item .text-danger{color: #667085 !important;}
.select-dropdown__option {
    color: #7C7C7C; font-weight: 500; font-size:16px; line-height: 24px;
  }

  .select-dropdown__menu {
	color: #7C7C7C; font-weight: 500; font-size:16px; line-height: 24px;
  }

  .select-dropdown__control--menu-is-open .select-dropdown__indicator {
	transform: rotate(180deg); /* Rotate arrow */
  }

  .select-dropdown__indicator {
	  color: #040F0F;
  }

  .select-dropdown__option--is-selected {
	background-color: #fff !important; 
	color: #7C7C7C !important; 
  }
  
  .select-dropdown__option--is-focused {
	background-color: #ffffff !important;
	color: #7C7C7C !important; 
	box-shadow: none !important;
	outline: none !important;
  }
  .select-dropdown__control--is-focused {
	border-color: hsl(0, 0%, 80%) !important;
	box-shadow: none !important; 
  }
  /* Style for disabled options */
.select-dropdown__option--is-disabled {
	color: #D3D3D3 !important; /* Light grey color for disabled state */
	cursor: not-allowed !important; /* Change cursor to indicate that the option is disabled */
	pointer-events: none !important; /* Prevent interaction with disabled options */
  }
  
  .select-dropdown__option--is-disabled.select-dropdown__option--is-selected {
	background-color: #E1E1E1 !important; /* Slightly darker grey for selected disabled option */
  }

  /* select-dropdown__dropdown-indicator{
	display: block !important;
  } */
  .select-dropdown__indicator.select-dropdown__dropdown-indicator {
    display: block !important;
}
  
  .invalid__control--is-focused {
	border-color: #dc3545 !important;
	box-shadow: none !important; 
  }

  .confirm-popup {
    z-index: auto;
  }
  .modal-footer{
	border-top: none;
  }
@media only screen and (max-width:991px){
	.container{max-width: 100% !important;}
}


@media only screen and (max-width:767px){
	h2{font-size: 20px; line-height: 28px;}
	.spinner-border{width: 45px; height: 45px;}
    .toast-container{position: fixed !important; z-index: 99999; bottom:45px !important;}
}

@media only screen and (max-width: 768px) {
	.location-dropdown .select-dropdown__control {
	  margin-bottom: 12px !important; 
	}

	.location-dropdown .invalid__control{margin-bottom: 12px;}
  }