.login-header {
	display: flex;
	width: 100%;
	padding: 44px;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1;
}

.logo {
	max-width: 268px;
	width: 100%;
	display: inline-block;
}

.signin-text {
	color: #040F0F;
	margin-bottom: 8px;
}

.signin-p {
	color: #667085;
	margin-bottom: 24px;
}

.signin-field {
	font-weight: 500;
}

.form-check-input {
	color: #D0D5DD !important;
	font-size: 16px !important;
}

.signin-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 370px;
	width: 100%;
	height: calc(100vh - 58px);
	margin: 0 auto;
}

.form-field {
	margin-bottom: 24px;
}

.signin-wrapper h2 {
	font-size: 40px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.4px;
	text-transform: capitalize;
}

.signin-wrapper p {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
}

.signin-wrapper Form {
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px;
	width: 100%;
}

.signin-wrapper Form Form.Group Form.Label {
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
}

.signin-wrapper Form Button {
	width: 100%;
	height: 44px;
	background: #2E3394;
}

.signin-box-container {
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
}

.fgt-pwd {
	text-decoration: none;
	font-size: 14px;
	color: #2E3394;
	font-weight: 500;
}

.form-check-label {
	font-weight: 500 !important;
	margin-top: 3px;
}

.submit-btn {
	margin: 24px;
}

.form-check {
	color: #040F0F;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
}

.password-field {
	position: relative;
}

.password-toggle-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
	color: #667085;
	font-size: 24px;
}

.email-field {
	position: relative;
}

.email-clear-icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	cursor: pointer;
	font-size: 24px;
	color: #667085;
}

.mob-logo {
	display: none;
}

/*Responsive*/

@media only screen and (max-width:1650px) {
	.login-header {
		padding: 20px;
	}

}

@media only screen and (max-width:1365px) {
	.signin-wrapper {
		height: calc(100vh - 62px);
	}
}
@media only screen and (max-width:1199px) {
    .navbar-brand .mob-logo{
        display: block;
        max-width: 268px;
    }
    .navbar-brand{
        margin-right: 0;
    }
    .logo{
        text-align: center;
        max-width: 268px !important;
    }
}
@media only screen and (max-width:1000px) {
    .has-error .invalid-feedback{
        position: absolute;
    }
    .without-login{
        justify-content: center;
    }
	.desk-logo {
		display: none !important;
	}

	.mob-logo {
		display: block !important;
	}
}
@media only screen and (max-width:991px) {
	.signin-wrapper {
		height: auto;
        /* min-height: 496px; */
	}

	.login-header {
		position: relative;
	}
}

@media only screen and (max-width:767px) {
	.login-header {
		justify-content: center;
		padding-top: 75px;
		margin-bottom: 60px;
		padding-bottom: 0;
	}

	.logo {
		margin: 0 auto;
		max-width: 130px;
	}

	.signin-wrapper {
		padding: 0 15px;
	}

	

	.signin-wrapper p {
		font-size: 14px;
	}
    .without-login .logo {
        max-width: 268px !important;
    }
}

@media only screen and (max-width:575px) {
	.signin-wrapper {
		margin-bottom: 100px;
	}
}

@media only screen and (max-width:479px) {
	.signin-wrapper p {
		font-size: 14px;
	}

	.desk-logo {
		display: none;
	}

	.mob-logo {
		display: block;
	}
}