.table-ctn {
    margin-left: 5%;
    margin-top: 5% !important;
    width: 90%;
    height: 75vh !important;
    overflow-y: auto;
}

.table-title-ctn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
}

.back-button {
    text-decoration: none;
    color: #667085 !important;
    font-weight: 600;
    font-family: Inter;
}

.table-title-ctn .back-button {cursor: pointer;}

h2 {
    margin: 0;
    font-weight: 600;
    font-family: Inter;
}

.add-btn {
    background: #2e3394 !important;
    width: 180px;
    padding: 8px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 10px;
}

.add-btn-narrow {
    width: 95%;
    background: #2e3394 !important;
    padding: 8px !important;
    margin: 10px;
}

.col-names {
    background-color: #000 !important;
    color: white !important;
}

.black-background {
    background-color: black !important;
    color: white;
}

.table-btn {
    text-decoration: none;
    color: #000;
    background: none;
    border: none;
    padding: 0;
}

.field-names {
    font-weight: 500;
}

.row-even {
    background-color: white !important;
}

.row-odd {
    background-color: lightgray !important;
}

.tool-container {
    display: flex;
    justify-content: space-between;
    /* padding: 16px; */
}

.half-tool-container {
    display: flex;
    padding: 16px;
}

.tool-name {
    padding-right: 16px;
}

.mutliselect {
    width: 180px;
    padding-left: 16px;
}

.download-btn {
    text-decoration: none;
    color: #000;
    background: none;
    border: 1px solid;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 4px;
    margin-left: 16px;
    height: 28px;
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 5px;
}

.pagination-ctn {
    display: flex;
    justify-content: space-between;
}

.pagination {
    display: flex;
    justify-content: space-between;
}

.pagination > li {
    list-style: none;
    border: 0.3px solid;
}
.pagination > li > a,
.pagination > li > span {
    float: left;
    line-height: 1;
    border: 1px solid #ddd;
    margin-left: -1px;
}

.pagination > li.active > a {
    color: #fff;
    background-color: #2e3394;
    border-color: #2e3394;
}
.pagination > li > a:hover {
    background-color: #2e3394;
    color: white;
    cursor: pointer;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
}

.pagesize-ctn {
    margin-right: 16px;
}

.pagesize-box {
    width: 50px;
    margin-left: 4px;
    margin-right: 4px;
}

.mutliselect {
    width: 250px;
}
@media only screen and (max-width:1000px){
    .form-row .text-center {
        text-align: right !important;
    }
    .form-row .col {
        padding: 2px 30px !important;
    }
    .form-row .row {
        padding-right: 0;
    }
    .form-row.row-odd {
        padding-top: 8px;
        padding-bottom: 8px;
    }
    .form-row.row-even, .form-row.row-odd{
        margin-bottom: 10px !important;
    }
}
@media only screen and (max-width:767px){
    .form-row .col{
        padding: 0 !important;
    }
    .form-row .row{
        padding-left: 22px;
    }
}