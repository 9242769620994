.form-table-ctn {
    /* margin-left: 5%; */
    margin: auto;
    margin-top: 20px !important;
    padding: 0 20px;
    height: 73vh !important;
    overflow-y: auto;
}

.tool-container{
    padding: 0;
}
@media (max-width: 1000px) {
    .tool-container{
        padding: 0px 7px 10px 2px !important;
        margin-top: 10px;
    }
    .tool-container .mutliselect{
        padding-left: 0;
    }
    .mobile-download .download-btn{
        margin-left: 0;
        padding: 18px 18px !important;
    }
    .form-table-ctn .add-btn-narrow{
        margin-bottom: 10px;
    }
}
@media (max-width: 768px) {
    .form-table-ctn {
        overflow-x: hidden;
        padding-bottom: 30px;
    }
}

.table-title-ctn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.back-button {
    text-decoration: none;
    color: #667085 !important;
    font-weight: 600;
    font-family: Inter;
    font-size: 24px;
}
.list-arrow{
    font-size: 24px;
    cursor: pointer;
    margin-top: -4px !important;
}

h2 {
    margin: 0;
    font-weight: 600;
    font-family: Inter;
}

.add-btn {
    background: #2e3394 !important;
    width: 180px;
    padding: 8px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 10px;
}

.add-btn-narrow {
    width: 95%;
    background: #2e3394 !important;
    padding: 8px !important;
    margin: 10px;
}

.col-names {
    background-color: #000 !important;
    color: white !important;
}

.black-background {
    background-color: black !important;
    color: white;
}

.table-btn {
    text-decoration: none;
    color: #000;
    background: none;
    border: none;
    padding: 0;
}

.field-names {
    font-weight: 500;
}

.row-even {
    background-color: white !important;
}

.row-odd {
    background-color: lightgray !important;
}

.tool-container {
    display: flex;
    justify-content: space-between;
    /* padding: 16px; */
    flex-wrap: wrap;
    gap: 10px;
}

.half-tool-container {
    display: flex;
    /* padding: 16px; */
    flex-wrap: wrap;
    gap: 10px;
    /* justify-content: space-between; */
}

.tool-name {
    padding-right: 16px;
    /* margin-top: 10px; */
}

.mutliselect {
    width: 180px;
    padding-left: 16px;
    height: 40px!important;
}

.download-btn {
    display: flex;
    width: fit-content;
    align-items: center;
    gap: 5px;
    text-decoration: none;
    color: #000;
    background: none;
    border: 1px solid #D9D9D9 !important;  
    margin-left: 16px;
     padding: 10px 20px !important;
}

.form-listing-sec .download-btn{height: 40px;}

.apply-btn, .mobile-apply{padding: 9px 20px; background: transparent; background: #2e3394; color: #fff; font-size: 14px; border:none; box-shadow:none; height: 40px;}
.apply-btn:hover{background: #3D95A8;}

/* .pagination-ctn {
    display: flex;
    justify-content: space-evenly !important;
    position: relative;
    margin: 1vh;
    padding-top: 20px;
    bottom: 2vh;
    width: 100%;
    z-index: 10;
}

.pagination {
    display: flex;
    justify-content: space-between;
}

.pagination > li {
    list-style: none;
    border: 0.3px solid;
}
.pagination > li > a,
.pagination > li > span {
    float: left;
    line-height: 1;
    border: 1px solid #ddd;
    margin-left: -1px;
}

.pagination > li.active > a {
    color: #fff;
    background-color: #2e3394;
    border-color: #2e3394;
}
.pagination > li > a:hover {
    background-color: #2e3394;
    color: white;
    cursor: pointer;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
} */

.pagesize-ctn {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding: 10px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 16px;
}

.pagination-outer{margin-top: 45px; display: flex; justify-content: space-between}

  
.paginations-inner{display: flex; align-items: center; }
.paginations-inner button{width: 24px; height: 24px; background: #040F0F; border-radius: 4px; display: flex; justify-content: center; align-items: center; padding: 1px;}
.paginations-inner input{width: 36px; height: 36px; border-radius: 8px; border:1px solid #34343480; display: flex; align-items: center; justify-content: center; margin: 0 10px; text-align: center;}
.paginations-inner span{margin-left: 10px; color: #040F0F; font-size: 14px; }

.pagesize-ctn input{width: 36px; height: 36px; border-radius: 8px; border:1px solid #34343480; display: flex; align-items: center; justify-content: center; margin: 0 10px; text-align: center;}
.pagesize-ctn label{color: #040F0F; font-size: 14px; }


.form-listing-sec .table-title{margin-right: auto; margin-left: 16px; align-items: center;}
.form-listing-sec .table-title-ctn{align-items: center;}

.form-listing-sec .table-title span{color: #667085;  font-weight: 400;}

.alert-table {text-align: center; margin-bottom: 0;}
.alert-table td, .alert-table th{font-size: 14px; line-height: 16px; font-weight: 400; padding: 16px;}
.alert-table td{color: #040F0F;}
.alert-table tr:nth-child(2n+1) td{background: #fff !important;}
.alert-table tr:nth-child(2n) td{background: #E0E0E0;}

.date-filter{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin-right: 16px; */
}

.date-filter .react-select__indicator-separator{display: none;}
.date-filter .react-select__dropdown-indicator {
  color: #040F0F !important;
}
.date-filter .react-select__control{border: 1px solid #D9D9D9; border-radius: 4px; min-width: 226px; height: 40px; font-size: 16px; color: #667085;}
.date-filter .react-select__control--is-focused{border: 1px solid #D9D9D9; box-shadow: none;}
.date-filter .react-select__value-container{padding: 0 16px; }
.date-filter .react-select__single-value {
  color: #667085 !important;
}


/* Responsive settings */
@media (max-width: 768px) {
    /* .pagesize-ctn {
        flex-direction: column;
    }
    .pagesize-box {
        width: 100%;
        margin-top: 10px; 
        margin-bottom: 12px;
    } */
}

.mutliselect {
    width: 200px;
}

.text-truncate {
    max-width: 400px; /* Adjust this value based on your layout */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tool-container{align-items: center; padding: 16px; }
  .half-tool-container{align-items: center; padding-left: 0 !important;}
  .left-team-nm .download-btn{padding: 10px 16px; }

.left-team-nm select{border: 1px solid #D9D9D9; padding: 8px 16px; color: #000; background: var(--rmsc-bg); height: 40px; border-radius: 4px;} 

.no-form-data {
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #667085 !important;
    font-size: 24px !important;
    line-height: 24px !important;
}

@media (max-width: 768px) {
    .text-truncate {
        max-width: 200px; /* Adjust this value based on your layout */
    }
}

@media only screen and (max-width:767px){
    .table-title{
     justify-content: center;
     margin-top: 16px;
     margin-bottom: 32px !important;
   }

   .form-row  .field-names {font-size: 13px; line-height: 16px;}
   .form-row  .text-center {font-size: 13px; line-height: 16px;}
   .form-row{margin-bottom: 8px !important; padding: 8px 0;}

   .pagination-outer{margin-top: 20px; flex-wrap: wrap; justify-content: center;  margin-bottom: 45px;}
   .paginations-inner{width: 100%; justify-content: center; margin-bottom: 20px;}
   .pagesize-ctn{width: 100%; justify-content: center; margin: 0; padding: 0px;}
   .pagination-outer  div:first-child{display: none;}

   .pagesize-ctn{flex-direction: row;}
   .table-title.mb-2{margin-bottom: 0px !important;}
   .table-title  h4{text-align: center; }
   .table-title h4 span{width: 100%; display:block; max-width: 100% ;}

   /* .mobile-form-in select{border: 1px solid #D9D9D9; padding: 8px 16px; color: #000; background: var(--rmsc-bg); height: 40px; border-radius: 4px; width: 100%; margin-top: 16px;} */
   
   .mobile-download .download-btn{ width: 100%;  height: 40px; margin-left: 0px; justify-content: center;}
   .mobile-download{width: 100%; margin-right: 0px; text-align: center;}
   .mobile-select{width: 100%; margin-top: -16px;}
   .mobile-select .mutliselect{width: 100%; height: 40px;}
   .mobile-apply{width: 100%; height: 40px; background: #2e3394;  color: #fff; border: none; box-shadow: none; border-radius: 4px; font-size: 14px;}
   .mobile-apply:hover{background: #3D95A8; }
   
   .date-filter{
    display: block;
    width: 100%;
}
.date-filter .react-select__control{margin-top: 10px;}
.form-table-ctn .add-btn-narrow{
    width: 100%;
}
.mobile-download .download-btn{
    margin-bottom: 18px;
}
 }


@media only screen and (max-width:575px){
 .table-title h4 span{
   /* max-width: 20ch; */
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   display: block;
 }
 .no-data {
   display: block !important;
   margin-top: 80px !important;
   height: auto !important;
    }


 .mutliselect{width: 100%; padding-left: 0px !important;}
 .form-table-ctn {
    overflow-x: hidden;
    padding-bottom: 30px;
}

  .form-row{padding: 10px 10px;}
}


